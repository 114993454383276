export const defaultTheme = {
  colorTextDefault: '#ffffff',
  colorHeaderDefault: 'rgb(34, 34, 34)',
  colorWhite: '#ffffff',
  colorBg: 'rgba(24, 24, 24, 1)',
  colorBgListHeader: '#a77ab3',
  colorBgList: 'rgba(167,122,179,0.3)',

  colorForBorderList: 'none',

  colorForBgButton: 'rgba(252, 155, 39, 1)',
  colorForButton: 'rgba(32,70,105, 1)',
  colorForButtonHover: 'rgb(24,52,79)',
  colorForBorderInput: 'rgba(0, 0, 0, 0.1)',
  colorForBgInput: 'rgba(246, 246, 246, 1)',

  pageWidth: '1920px',
  pagePaddingLeft: '205px',
  pagePaddingRight: '240px',
  pagePaddingLaptopLeft: '60px',
  pagePaddingLaptopRight: '60px',
  fontFamily: '"Arimo", "Arial", sans-serif',
  fontSize: '12px',
  lineHeight: '1.2rem',
  fontWeight: '400',

  paddingHeader: '0',
  borderRadiusForList: '0',
  boxShadow: 'none',

  desktopWidth: '1920px',
  laptopLWidth: '1366px',
  laptopWidth: '1200px',
  tabletWidth: '1024px',
  mobileWidth: '768px',

  pagePaddingMobile: '3%'
};
